/* eslint-disable no-useless-escape */
import { mapGetters, mapActions } from 'vuex'
import { currencyFormat, showVueToast } from '@/utils'

export default {
  name: 'short-course',
  components: {
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Search'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Close'),
    G2Academy: () => import(/* webpackChunkName: "g-2-academy" */ '@/components/icons/G2Academy'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ArrowForward'),
    Loading: () => import(/* webpackChunkName: "illustration-loading" */ '@/components/illustration/Loading'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  data() {
    return {
      contentVisible: false,
      carouselOptions: [],
      isSearchContent: false,
      isLoadContent: false,
      renderComponent: true,
      isEmptyResult: false,
      visibleEnrollModal: false,
      emailDisabled: false,
      programName: '',
      params: {
        page: 0,
        size: 100,
        class_name: ''
      },
      shortCourseContent: [],
      coreClassId: null,
      errors: {},
      classes: [],
      searchClass: [],
      totalData: 0,
      keyword: '',
      showResult: true,
      registrationPayload: {
        name: null,
        email: null,
        mobilePhone: ''
      },
      phoneInputType: 'number',
      isClassListEmpty: null,
      errorValidation: {
        name: false,
        email: false,
        mobilePhone: false
      }
    }
  },
  created() {
    this.isClassListEmpty = true
  },
  mounted() {
    this.getShortCourseContent()
    this.getProfile()
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('common', ['isMobile']),
    studentId() {
      return localStorage.getItem('student_id')
    },
    maxNameSize() {
      if (this.isMobile) return 50
      return 95
    },
    mobilePhone() {
      if (this.registrationPayload.mobilePhone) return this.registrationPayload.mobilePhone.toString()
      return '0'
    },
    fullName() {
      return this.registrationPayload.name
    },
    email() {
      return this.registrationPayload.email
    },
    isErrorEmpty() {
      return Object.values(this.errorValidation).includes(true)
    }
  },
  watch: {
    visibleEnrollModal(value) {
      this.errors = {}
      if (!value) {
        this.programName = ''
        this.coreClassId = ''
        this.registrationPayload = {
          name: '',
          email: '',
          mobilePhone: ''
        }
      }
    },
    mobilePhone(value) {
      if (value.length >= 15) {
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value.length < 11) {
        this.errors['mobilePhone'] = 'Minimum 11 digit, Maximum 15 digit'
        this.errorValidation.mobilePhone = true
      } else {
        this.errors['mobilePhone'] = ''
        this.errorValidation.mobilePhone = false
      }
    },
    fullName(value) {
      if (value === '') {
        // eslint-disable-next-line quotes
        this.errors['name'] = `Full name can't be empty!`
        this.errorValidation.name = true
      } else {
        this.errors['name'] = ''
        this.errorValidation.name = false
      }
    },
    email(value) {
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (value === '') {
        // eslint-disable-next-line quotes
        this.errors['email'] = `Email can't be empty!`
        this.errorValidation.email = true
      } else if (!regex.test(this.registrationPayload.email)) {
        this.errors['email'] = 'Email format is invalid!'
        this.errorValidation.email = true
      } else {
        this.errors['email'] = ''
        this.errorValidation.email = false
      }
    },
    keyword() {
      // if (this.keyword.length >= 3) {
      //   this.isSearchContent = true
      //   // this.params.class_name = this.keyword
      //   this.getShortCourseContent()
      //   return
      // }

      // if (this.isSearchContent) {
      //   this.params.class_name = ''
      //   this.getShortCourseContent()
      //   this.isSearchContent = false
      // }
    }
  },
  filters: {
    currencyFormat
  },
  methods: {
    ...mapActions('home', ['getProgramContent']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['enrollV2', 'getFormToken', 'newEnrollV2']),
    ...mapActions('student', ['getStudentDetail']),
    filterClassNoPrice(classes) {
      return classes.filter((item) => item.price || item.price === 0)
    },
    getProfile() {
      if (this.studentId) {
        this.showLoading()
        this.getStudentDetail({ studentId: this.studentId })
          .then((res) => {
            this.registrationPayload.name = res.name
            this.registrationPayload.email = res.email
            this.registrationPayload.mobilePhone = res.phoneNumber
            this.emailDisabled = true
            this.hideLoading()
          })
          .catch(() => {
            this.hideLoading()
          })
      }
    },
    getShortCourseContent() {
      if (this.isLoadContent) return
      this.isLoadContent = true
      this.carouselOptions = []
      this.shortCourseContent = []
      this.getProgramContent({ programName: 'Short Course', params: this.params }).then(
        (res) => {
          const response = res.classGroups.content
          if (response.length > 0) {
            response.map((program) => {
              const options = {
                type: 'slide',
                autoplay: false,
                pagination: true,
                arrows: false,
                keyboard: false,
                perPage: 4,
                drug: true,
                speed: 1000,
                interval: 5000,
                fixedHeight: '32rem',
                gap: '1rem',
                lazyLoad: 'nearby',
                breakpoints: {
                  767: {
                    perPage: 2
                  }
                }
              }
              // this.shortCourseContent = []
              // this.carouselOptions = []
              this.shortCourseContent.push({
                id: program.id,
                category: program.title,
                classes: program.classes
              })

              if (this.filterClassNoPrice(program.classes).length < 5) {
                options.pagination = false
              }

              if (this.filterClassNoPrice(program.classes).length >= 3 && this.isMobile) {
                options.pagination = true
              }

              this.carouselOptions.push(options)
            })
            this.isLoadContent = false
            this.isEmptyResult = false
            this.isClassListEmpty = false
          } else {
            this.isEmptyResult = true
            this.isLoadContent = false
          }
        },
        () => { }
      )
    },
    registerProgram(coreClassId, className) {
      if (this.isLoggedIn) {
        this.coreClassId = coreClassId
        return this.getToken()
      }

      this.coreClassId = coreClassId
      this.programName = className
      this.toogleEnrollModal()
    },
    getToken() {
      this.showLoading()
      this.getFormToken()
        .then((response) => this.enrollProgram(response.data.data))
        .catch(() => showVueToast('There was a problem, please try again later!', 'error', 2000))
    },
    enrollProgram(token) {
      this.newEnrollV2({
        payloads: {
          coreClassId: this.coreClassId,
          email: this.registrationPayload.email,
          name: this.registrationPayload.name,
          mobilePhone: this.registrationPayload.mobilePhone
        },
        formToken: token
      }).then(
        () => {
          this.errors = []
          this.visibleEnrollModal = false
          this.hideLoading()
          setTimeout(() => {
            this.$router.push('/thankyou/short-course')
          }, 500)
        },
        () => {
          this.hideLoading()
          showVueToast('Failed!', 'error', 2000)
        }
      )
    },
    toogleEnrollModal() {
      this.visibleEnrollModal = !this.visibleEnrollModal
    },
    clearKeyword() {
      this.keyword = ''
    },
    toDetail(slug) {
      this.$router.push(`/class/regular/${slug}`).catch(() => true)
    }
  }
}
